window.$ = window.jQuery = require('jquery')
require('./bootstrap');
// require('admin-lte');

import Alpine from 'alpinejs';

// require('@fortawesome/fontawesome-free/js/all.min.js');

window.Alpine = Alpine;

Alpine.start();
